<template>
	<div class="detailContainer">
		<div class="page-title-detail">编辑楼栋</div>
		<div>
      <div class="item-box">
				<span class="item-left">楼栋号：</span>
				<el-input class="rantHouseName" v-model="building_number" onkeyup="value=value.replace(/[^\d]/g,'')"  show-word-limit placeholder="请输入"></el-input>
			</div>
			<div class="item-box">
				<span class="item-left">单元号：</span>
        <div>
          <el-button class="submitBtn" type="primary" plain @click="addUnit">新增单元</el-button>
        </div>
			</div>
      <div class="item-box">
				<span class="item-left"></span>
				<div class="unit_class">
          <ul>
            <li v-for="(item,index) in unitsList" :key="index" class="unit_item_wrap">
              <img @click="deleteUnit(index)" class="img" src="../../../assets/icons/delete.png"/>
              <el-input class="unit_item" v-model="item.unit_number" onkeyup="value=value.replace(/[^\d]/g,'')"  placeholder="请输入"></el-input>
            </li>
          </ul>
        </div>
			</div>
			<div class="sub-box">
				<el-button class="submitBtn" type="primary" plain @click="submit">提交</el-button>
			</div>
		</div>
	</div>
</template>
<script>
  import {getLouDongDetailsFn,editLouDongFn} from '@/api/louDong.js'
	export default {
		props: [],
		data() {
			return {
        id:'',
        page:'',
        last_page:'',

        building_number:'',
        units:'',
        unitsList:[]
			};
		},
    created() {
      this.id = this.$route.query.id
    },
		mounted() {
      this.getLouDongDetails()
		},
		methods: {
      //删除单元
      deleteUnit(index){
        this.unitsList.splice(index,1)
      },
      //新增单元
      addUnit(){
        this.unitsList.push({
          id:0,
          unit_number:''
        })
      },
			//提交
			submit() {
				editLouDongFn({
          id:this.id,
          api_token:localStorage.getItem('token1'),
					building_number:this.building_number,
					units:this.unitsList,
				}).then(res => {
					if (res.code == 200) {
						this.$confirm("提交成功", "提交成功", {
							confirmButtonText: "确定",
							callback: () => {
								this.$router.push({
									path: "/louDongList",
									query: {
										page: this.page,
										last_page: this.last_page
									}
								});
							}
						});
					}
				});
			},
			//详情
			getLouDongDetails() {
				getLouDongDetailsFn({
					api_token : localStorage.getItem('token1'),
					id: this.id
				}).then(res => {
					this.building_number = res.datas.building_number;
					this.unitsList = res.datas.units;
				});
			},
		},
	};
</script>
<style scoped lang='scss'>
	@import url("../../../style/detail.css");
  li{
    list-style: none;
  }
  .unit_item_wrap{
    position: relative;
    .img{
      position: absolute;
      top: -10px;
      right:-10px;
      width: 30px;
      height: 30px;
      z-index: 999;
    }
  }
  .unit_item{
    margin-right: 20px;
    margin-bottom: 10px;
  }
</style>
